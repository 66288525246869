import { useRouter } from 'next/router';
import startsWith from 'lodash/startsWith';

import { CompanyNanoID } from '../../../../companies/companiesTypes';
import { UserNanoID } from '../../../../users/usersTypes';
import { TeamTeamTypeEnum } from '../../../../teams/teamsTypes';

import {
  paymentsMenuLinks,
  useCompactPaymentsSecondaryMenu
} from './hooks/useCompactPaymentsSecondaryMenu';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { MenuPlainLink } from '../MenuPlainLink';
import { SecondaryMenuWrapper } from '../../../../../app/components/menus/SecondaryMenuWrapper';
import { CompanyWithdrawModalButton } from '../../../../teams/components/modalButtons/CompanyWithdrawModalButton';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { CompanyPaymentsGeneralLedgersList } from '../../../../teams/helpers/CompanyPaymentsGeneralLedgersList';

import { menuKeys } from '../../../../../locales/keys';
import { InvoicesPermissions } from '../../../../invoices/invoicesConstants';
import { InvoicePath } from '../../../../invoices/InvoicePath';
import { GeneralLedgerID } from '../../../../generalLedgers/generalLedgersTypes';

interface WorkspaceSecondaryMenuProps {
  currentUserNanoId: UserNanoID;
  currentUserCompanyNanoId?: CompanyNanoID;
  defaultGeneralLedgerId?: GeneralLedgerID;
  teamType?: TeamTeamTypeEnum;
}

function CompactPaymentsSecondaryMenu({
  currentUserNanoId,
  currentUserCompanyNanoId,
  defaultGeneralLedgerId,
  teamType
}: WorkspaceSecondaryMenuProps) {
  const currentUser = useCurrentUser();
  const { asPath } = useRouter();

  const { activeMenu, config, permissions } = useCompactPaymentsSecondaryMenu();

  return (
    <SecondaryMenuWrapper
      activeMenu={activeMenu}
      i18nTitle={menuKeys.tabs.payments}
      isCompact
      menuLinks={paymentsMenuLinks(permissions)}
    >
      {teamType === TeamTeamTypeEnum.CLIENTS &&
      startsWith(asPath, InvoicePath.payments()) ? (
        <CheckPermissions
          action={InvoicesPermissions.READ_PAYMENTS_MENU_GENERAL_LEDGERS}
        >
          <CompanyPaymentsGeneralLedgersList
            companyNanoId={currentUserCompanyNanoId}
            defaultGeneralLedgerId={defaultGeneralLedgerId}
            companyContractorNanoId={
              currentUser.client ? undefined : currentUser.nanoId
            }
          />
        </CheckPermissions>
      ) : null}

      {currentUserCompanyNanoId && !currentUser.client ? (
        <CheckPermissions
          action={InvoicesPermissions.READ_PAYMENTS_MENU_WITHDRAW_MODAL_BUTTON}
        >
          <div className="p-4">
            <CompanyWithdrawModalButton
              companyOwnerNanoId={currentUserNanoId}
              companyNanoId={currentUserCompanyNanoId}
              withBalance={currentUser.hasPermissions(
                InvoicesPermissions.READ_PAYMENTS_MENU_WITHDRAW_MODAL_BUTTON_BALANCE
              )}
            />
          </div>
        </CheckPermissions>
      ) : null}

      <div className="p-4">
        {config[activeMenu]?.map(
          ({ action, active, count, href, i18nText, icon }) => (
            <CheckPermissions key={href} action={action}>
              <MenuPlainLink
                active={active}
                count={count}
                href={href}
                i18nText={i18nText}
                icon={icon}
              />
            </CheckPermissions>
          )
        )}
      </div>
    </SecondaryMenuWrapper>
  );
}

export default CompactPaymentsSecondaryMenu;
