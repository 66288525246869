import { gql } from 'graphql-request';

import {
  UserUUID,
  UserNanoID,
  UserSystemAccount,
  UserBlocked,
  UserPayoneerId,
  UserPayoneerIdPe,
  UserPayoneerIdPeAvltd,
  UserPayoneerType,
  UserIsMentor,
  UserID,
  UserPayoneerIdPeAvLlc,
  UserPayoneerIdAvLlc,
  UserPayoneerIdAvltdUk
} from '../usersTypes';

export interface FetchUserSettingsQueryResponse {
  id: UserID;
  nanoId: UserNanoID;
  uuid: UserUUID;
  systemAccount: UserSystemAccount;
  blocked: UserBlocked;
  isMentor: UserIsMentor;
  payoneerId: UserPayoneerId;
  payoneerIdPe: UserPayoneerIdPe;
  payoneerIdAvltdUk: UserPayoneerIdAvltdUk;
  payoneerIdAvLlc: UserPayoneerIdAvLlc;
  payoneerIdPeAvltd: UserPayoneerIdPeAvltd;
  payoneerIdPeAvLlc: UserPayoneerIdPeAvLlc;
  payoneerIdType: UserPayoneerType;
}

export const FETCH_USER_SETTINGS_QUERY = gql`
  query UserSettings($uuid: ID!) {
    user(uuid: $uuid) {
      id
      nanoId
      uuid
      systemAccount
      blocked
      isMentor
      payoneerId
      payoneerIdPe
      payoneerIdAvltdUk
      payoneerIdAvLlc
      payoneerIdPeAvltd
      payoneerIdPeAvLlc
      payoneerIdType
    }
  }
`;
