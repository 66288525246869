import {
  UserPayoneerId,
  UserPayoneerIdPe,
  UserPayoneerIdPeAvltd,
  UserPayoneerType,
  UserFields,
  UserPayoneerIdAvLlc,
  UserPayoneerIdPeAvLlc,
  UserPayoneerIdAvltdUk
} from '../../usersTypes';
import { PayoneerAccountTypes } from '../../../../types';

interface GetUserPayoneerFieldValueByTypeUser {
  payoneerId: UserPayoneerId;
  payoneerIdPe: UserPayoneerIdPe;
  payoneerIdAvltdUk: UserPayoneerIdAvltdUk;
  payoneerIdAvLlc: UserPayoneerIdAvLlc;
  payoneerIdPeAvltd: UserPayoneerIdPeAvltd;
  payoneerIdPeAvLlc: UserPayoneerIdPeAvLlc;
  payoneerIdType: UserPayoneerType;
}

function getUserPayoneerFieldValueByType(
  user?: GetUserPayoneerFieldValueByTypeUser
) {
  switch (user?.payoneerIdType) {
    case PayoneerAccountTypes.PAYONEER_ID:
      return user?.[UserFields.PAYONEER_ID];
    case PayoneerAccountTypes.PAYONEER_ID_PE:
      return user?.[UserFields.PAYONEER_ID_PE];
    case PayoneerAccountTypes.PAYONEER_ID_AVLTD_UK:
      return user?.[UserFields.PAYONEER_ID_AVLTD_UK];
    case PayoneerAccountTypes.PAYONEER_ID_AV_LLC:
      return user?.[UserFields.PAYONEER_ID_AV_LLC];
    case PayoneerAccountTypes.PAYONEER_ID_PE_AVLTD:
      return user?.[UserFields.PAYONEER_ID_PE_AVLTD];
    case PayoneerAccountTypes.PAYONEER_ID_PE_AV_LLC:
      return user?.[UserFields.PAYONEER_ID_PE_AV_LLC];

    default:
      return null;
  }
}

export default getUserPayoneerFieldValueByType;
