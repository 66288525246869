import {
  CreatedAt,
  DateType,
  FetchItemCacheKey,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsSort,
  ID,
  UUID,
  UpdatedAt
} from '../../types';
import { UpdateItemsCacheKeys } from '../../types/updateItemsTypes';
import { ProjectNanoID } from '../projects/projectsTypes';

import { InvoiceID, InvoiceNanoID } from '../invoices/invoicesTypes';
import { UserID } from '../users/usersTypes';

export type InvoiceProcessingJobID = ID;
export type InvoiceProcessingJobUUID = UUID;
export type InvoiceProcessingJobCreatedAt = CreatedAt;
export type InvoiceProcessingJobUpdatedAt = UpdatedAt;
export type InvoiceProcessingJobStartedAt = DateType;
export type InvoiceProcessingJobCompletedAt = DateType;
export type InvoiceProcessingJobFailedAt = DateType;
export type InvoiceProcessingJobActionName = string;
export type InvoiceProcessingJobStatus = InvoiceProcessingJobStatuses;
export type InvoiceProcessingJobProgress = number;
export type InvoiceProcessingJobParams = Record<string, any>;

export type InvoiceProcessingJobUserID = UserID;
export type InvoiceProcessingJobInvoiceID = InvoiceID;

export type InvoiceProcessingJobErrorMessage = string;
export type InvoiceProcessingJobErrorBacktrace = string;

export interface FetchInvoiceProcessingJobsFilters {
  invoiceId?: InvoiceID;
  invoiceNanoId?: InvoiceNanoID;
  projectNanoId?: ProjectNanoID;
}

export enum InvoiceProcessingJobNames {
  ACCEPT = 'accept',
  START = 'start',
  CANCEL = 'cancel'
}

export enum InvoiceProcessingJobStatuses {
  INITIALIZED = 'initialized',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed'
}

export const enum FetchInvoiceProcessingJobsSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC'
}

export type FetchInvoiceProcessingJobsCacheKey = FetchItemCacheKey;
export type FetchInvoiceProcessingJobsSort = FetchItemsSort;
export type FetchInvoiceProcessingJobsPage = FetchItemsPage;
export type FetchInvoiceProcessingJobsLimit = FetchItemsLimit;

export type UpdateInvoiceProcessingJobsCacheKeys = UpdateItemsCacheKeys;
