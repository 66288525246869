import {
  FETCH_INVOICES_OVERDUE_AMOUNT_QUERY,
  FetchInvoicesOverdueAmountQueryMetaResponse,
  FetchInvoicesOverdueAmountQueryResponse
} from '../../../../invoices/queries/fetchInvoicesOverdueMeta.query';
import { GeneralLedgerID } from '../../../../generalLedgers/generalLedgersTypes';
import { UserNanoID } from '../../../../users/usersTypes';

import { useFinPaginatedInvoices } from '../../../../invoices/hooks/useFinPaginatedInvoices';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { MoneyHelper } from '../../../../../helpers/MoneyHelper';
import { Translate } from '../../../../../helpers/Translate';

import { InvoiceCache } from '../../../../invoices/InvoiceCache';

import { invoicesKeys } from '../../../../../locales/keys';

interface CompanyPaymentsOverdueAmountsProps {
  companyContractorNanoId?: UserNanoID;
  generalLedgerId: GeneralLedgerID;
}

function CompanyPaymentsOverdueAmounts({
  companyContractorNanoId,
  generalLedgerId
}: CompanyPaymentsOverdueAmountsProps) {
  const { invoicesMeta, invoicesFetched } = useFinPaginatedInvoices<
    FetchInvoicesOverdueAmountQueryResponse,
    FetchInvoicesOverdueAmountQueryMetaResponse
  >({
    cacheKey: companyContractorNanoId
      ? InvoiceCache.contractorInvoicesOverdueAmountCacheKey(
          companyContractorNanoId
        )
      : InvoiceCache.generalLedgerInvoicesOverdueAmountCacheKey(
          generalLedgerId
        ),
    initialFilters: {
      generalLedgerId:
        !companyContractorNanoId && generalLedgerId
          ? { eq: generalLedgerId }
          : undefined,
      companyContractorNanoId: companyContractorNanoId
        ? { eq: companyContractorNanoId }
        : undefined
    },
    query: FETCH_INVOICES_OVERDUE_AMOUNT_QUERY
  });

  return (
    <LoadingSkeleton loaded={invoicesFetched} count={1}>
      <div className="mt-3 text-2xs font-medium flex flex-wrap gap-x-2">
        {invoicesMeta?.notOverdueAmount ? (
          <div className="flex-1 whitespace-nowrap">
            <Translate id={invoicesKeys.notOverdue} />
            &nbsp;
            <span className="font-normal opacity-70">
              <MoneyHelper value={invoicesMeta?.notOverdueAmount || 0} />
            </span>
          </div>
        ) : null}

        {invoicesMeta?.overdueAmount ? (
          <div className="flex-1 whitespace-nowrap">
            <Translate id={invoicesKeys.overdue} />
            &nbsp;
            <span className="font-normal opacity-70">
              <MoneyHelper value={invoicesMeta?.overdueAmount || 0} />
            </span>
          </div>
        ) : null}
      </div>
    </LoadingSkeleton>
  );
}

export default CompanyPaymentsOverdueAmounts;
