import startsWith from 'lodash/startsWith';

import { MenuDropdownWithLinksItemData } from '../MenuDropdownWithLinks';

import { AvBillingInfoPath } from '../../../../avBillingInfos/AvBillingInfoPath';
import { BillPath } from '../../../../bills/BillPath';
import { ChatGptPromptPath } from '../../../../chatGptPrompts/ChatGptPromptPath';
import { DefaultSmartContractRulePath } from '../../../../defaultSmartContractRules/defaultSmartContractRulePath';
import { EmailTemplatePath } from '../../../../emailTemplates/EmailTemplatePath';
import { InvoicePath } from '../../../../invoices/InvoicePath';
import { ItemCategoryPath } from '../../../../itemCategories/ItemCategoryPath';
import { ItemTagPath } from '../../../../itemTags/ItemTagPath';
import { ItemTypePath } from '../../../../itemTypes/ItemTypePath';
import { JournalRecordImportsPath } from '../../../../journalRecordImports/JournalRecordImportsPath';
import { JournalRecordPath } from '../../../../journalRecords/JournalRecordPath';
import { PagePath } from '../../../../pages/PagePath';
import { PermissionPath } from '../../../../permissions/PermissionPath';
import { ReportPath } from '../../../../reports/ReportPath';
import { ReportSectionPath } from '../../../../reportSections/ReportSectionPath';
import { RolePath } from '../../../../roles/RolePath';
import { SectionPath } from '../../../../sections/SectionPath';
import { SentMessagePath } from '../../../../sentMessages/SentMessagePath';

import { AvBillingInfosPermissions } from '../../../../avBillingInfos/avBillingInfosConstants';
import { BillsPermissions } from '../../../../bills/billsConstants';
import { ChatGptPromptsPermissions } from '../../../../chatGptPrompts/chatGptPromptsConstants';
import { DefaultSmartContractRulesPermissions } from '../../../../defaultSmartContractRules/defaultSmartContractRulesConstants';
import { EmailTemplatesPermissions } from '../../../../emailTemplates/emailTemplateConstants';
import { InvoicesPermissions } from '../../../../invoices/invoicesConstants';
import { ItemCategoriesPermissions } from '../../../../itemCategories/itemCategoriesConstants';
import { ItemTypesPermissions } from '../../../../itemTypes/itemTypesConstants';
import { JournalRecordImportsPermissions } from '../../../../journalRecordImports/journalRecordImportsConstants';
import { JournalRecordsPermissions } from '../../../../journalRecords/journalRecordsConstants';
import { PagesPermissions } from '../../../../pages/pagesConstants';
import { PermissionsPermissions } from '../../../../permissions/permissionsConstants';
import { ReportSectionsPermissions } from '../../../../reportSections/reportSectionsConstants';
import { ReportsPermissions } from '../../../../reports/reportsConstants';
import { RolesPermissions } from '../../../../roles/rolesConstants';
import { SectionsPermissions } from '../../../../sections/sectionsConstants';
import { SentMessagesPermissions } from '../../../../sentMessages/sentMessagesConstants';

import { menuKeys } from '../../../../../locales/keys';
import { InvoiceProcessingJobsPath } from '../../../../invoiceProcessingJobs/InvoiceProcessingJobsPath';
import { InvoiceProcessingJobsPermissions } from '../../../../invoiceProcessingJobs/invoiceProcessingJobsConstants';

export const accessDropdownItemsData: MenuDropdownWithLinksItemData[] = [
  {
    href: RolePath.index(),
    permission: RolesPermissions.READ_ADMIN_MENU_ROLES_LINK,
    i18nText: menuKeys.links.roles,
    isActive: (pathName) => startsWith(pathName, RolePath.index())
  },
  {
    href: PermissionPath.index(),
    permission: PermissionsPermissions.READ_ADMIN_MENU_PERMISSIONS_LINK,
    i18nText: menuKeys.links.permissions,
    isActive: (pathName) => startsWith(pathName, PermissionPath.index())
  }
];

export const financeDropdownItemsData: MenuDropdownWithLinksItemData[] = [
  {
    href: BillPath.index(),
    permission: BillsPermissions.READ_ADMIN_MENU_INVOICES_LINK,
    i18nText: menuKeys.links.bills,
    isActive: (pathName) => startsWith(pathName, BillPath.index())
  },
  {
    href: AvBillingInfoPath.index(),
    permission: AvBillingInfosPermissions.READ_ADMIN_MENU_AV_BILLING_INFOS_LINK,
    i18nText: menuKeys.links.avBillingInfos,
    isActive: (pathName) => startsWith(pathName, AvBillingInfoPath.index())
  },
  {
    href: ItemCategoryPath.index(),
    permission: ItemCategoriesPermissions.READ_ADMIN_MENU_ITEM_CATEGORIES_LINK,
    i18nText: menuKeys.links.itemCategories,
    isActive: (pathName) => startsWith(pathName, ItemCategoryPath.index())
  },
  {
    href: ItemTypePath.default(),
    permission: ItemTypesPermissions.READ_ADMIN_MENU_DEFAULT_ITEM_TYPES_LINK,
    i18nText: menuKeys.links.defaultItemTypes,
    isActive: (pathName) => startsWith(pathName, ItemTypePath.default())
  },
  {
    href: ItemTypePath.index(),
    permission: ItemTypesPermissions.READ_ADMIN_MENU_ITEM_TYPES_LINK,
    i18nText: menuKeys.links.itemTypes,
    isActive: (pathName) => startsWith(pathName, ItemTypePath.index())
  },
  {
    href: ItemTagPath.index(),
    permission: ItemTypesPermissions.READ_ADMIN_MENU_ITEM_TAGS_LINK,
    i18nText: menuKeys.links.itemTags,
    isActive: (pathName) => startsWith(pathName, ItemTagPath.index())
  },
  {
    href: InvoicePath.depositInvoices(),
    permission: InvoicesPermissions.READ_ADMIN_MENU_INVOICES_LINK,
    i18nText: menuKeys.links.depositInvoices,
    isActive: (pathName) => startsWith(pathName, InvoicePath.depositInvoices())
  },
  {
    href: DefaultSmartContractRulePath.smartContractFees(),
    permission:
      DefaultSmartContractRulesPermissions.READ_ADMIN_MENU_SMART_CONTRACT_FEES_LINK,
    i18nText: menuKeys.links.smartContractFees,
    isActive: (pathName) =>
      startsWith(pathName, DefaultSmartContractRulePath.smartContractFees())
  },
  {
    href: JournalRecordPath.accountingTransactions(),
    permission:
      JournalRecordsPermissions.READ_ADMIN_MENU_ACCOUNTING_TRANSACTIONS_LINK,
    i18nText: menuKeys.links.accountingTransactions,
    isActive: (pathName) =>
      startsWith(pathName, JournalRecordPath.accountingTransactions())
  },
  {
    href: InvoicePath.index(),
    permission: InvoicesPermissions.READ_ADMIN_INVOICES_LINK,
    i18nText: menuKeys.links.invoices,
    isActive: (pathName) => startsWith(pathName, InvoicePath.index())
  },
  {
    href: JournalRecordImportsPath.transactionImports(),
    permission:
      JournalRecordImportsPermissions.READ_ADMIN_MENU_JOURNAL_RECORD_IMPORTS_MENU,
    i18nText: menuKeys.links.transactionsImports,
    isActive: (pathName) =>
      startsWith(pathName, JournalRecordImportsPath.transactionImports())
  },
  {
    href: InvoiceProcessingJobsPath.index(),
    permission:
      InvoiceProcessingJobsPermissions.READ_ADMIN_MENU_INVOICE_PROCESSING_JOBS_MENU,
    i18nText: menuKeys.links.invoiceProcessingJobs,
    isActive: (pathName) =>
      startsWith(pathName, InvoiceProcessingJobsPath.index())
  }
];

export const contentDropdownItemsData: MenuDropdownWithLinksItemData[] = [
  {
    href: PagePath.index(),
    permission: PagesPermissions.READ_ADMIN_MENU_PAGES_LINK,
    i18nText: menuKeys.links.pages,
    isActive: (pathName) => startsWith(pathName, PagePath.index())
  },
  {
    href: EmailTemplatePath.index(),
    permission: EmailTemplatesPermissions.READ_ADMIN_MENU_EMAIL_TEMPLATES_LINK,
    i18nText: menuKeys.links.emailTemplates,
    isActive: (pathName) => startsWith(pathName, EmailTemplatePath.index())
  },
  {
    href: SectionPath.index(),
    permission: SectionsPermissions.READ_ADMIN_MENU_SECTIONS_LINK,
    i18nText: menuKeys.links.sections,
    isActive: (pathName) => startsWith(pathName, SectionPath.index())
  },
  {
    href: ReportSectionPath.index(),
    permission: ReportSectionsPermissions.READ_ADMIN_MENU_REPORT_SECTIONS_LINK,
    i18nText: menuKeys.links.reportSections,
    isActive: (pathName) => startsWith(pathName, ReportSectionPath.index())
  },
  {
    href: ChatGptPromptPath.index(),
    permission: ChatGptPromptsPermissions.READ_ADMIN_MENU_CHAT_GPT_PROMPTS_LINK,
    i18nText: menuKeys.links.chatGPTPrompts,
    isActive: (pathName) => startsWith(pathName, ChatGptPromptPath.index())
  }
];

export const reportsDropdownItemsData: MenuDropdownWithLinksItemData[] = [
  {
    href: ReportPath.index(),
    permission: ReportsPermissions.READ_ADMIN_MENU_REPORTS_LINK,
    i18nText: menuKeys.links.reports,
    isActive: (pathName) => startsWith(pathName, ReportPath.index())
  },
  {
    href: SentMessagePath.index(),
    permission: SentMessagesPermissions.READ_ADMIN_MENU_SENT_MESSAGES_LINK,
    i18nText: menuKeys.links.sentMessages,
    isActive: (pathName) => startsWith(pathName, SentMessagePath.index())
  }
];

export const accessPermissions = accessDropdownItemsData.map(
  (accessDropdownItem) => accessDropdownItem.permission
);

export const financePermissions = financeDropdownItemsData.map(
  (financeDropdownItem) => financeDropdownItem.permission
);

export const contentPermissions = contentDropdownItemsData.map(
  (contentDropdownItem) => contentDropdownItem.permission
);

export const reportsPermissions = reportsDropdownItemsData.map(
  (reportsDropdownItem) => reportsDropdownItem.permission
);
