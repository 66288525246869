import { useMemo } from 'react';

import { ClassName } from '../../../../types';
import { GeneralLedgerID } from '../../../generalLedgers/generalLedgersTypes';
import { IconsEnum } from '../../../../assets/icons/types';
import { TeamNanoID } from '../../teamsTypes';
import { UserNanoID } from '../../../users/usersTypes';

import { useReactQueryState } from '../../../common/hooks/base/reactQuery/useReactQueryState';

import { CreateGeneralLedgerModalButton } from '../../../generalLedgers/components/modalButtons/CreateGeneralLedgerModalButton';
import {
  CompanyPaymentsGeneralLedgersFilter,
  CompanyPaymentsGeneralLedgerType
} from '../CompanyPaymentsGeneralLedgersFilter';
import { CompanyPaymentsOverdueAmounts } from '../../components/content/CompanyPaymentsOverdueAmounts';
import { UpdateGeneralLedgerModalButton } from '../../../generalLedgers/components/modalButtons/UpdateGeneralLedgerModalButton';

import { CheckPermissions } from '../../../../helpers/CheckPermissions';
import { TooltipPlacement } from '../../../../helpers/tooltips/tooltipsConstants';

import { GeneralLedgerCache } from '../../../generalLedgers/GeneralLedgerCache';
import { generalLedgersKeys } from '../../../../locales/keys';
import {
  companyPaymentsMenuGeneralLedgerKey,
  TeamsPermissions
} from '../../teamsConstants';

interface CompanyPaymentsGeneralLedgersListProps {
  className?: ClassName;
  companyNanoId: TeamNanoID;
  companyContractorNanoId?: UserNanoID;
  defaultGeneralLedgerId?: GeneralLedgerID;
}

function CompanyPaymentsGeneralLedgersList({
  companyNanoId,
  companyContractorNanoId,
  defaultGeneralLedgerId
}: CompanyPaymentsGeneralLedgersListProps) {
  const cacheKey =
    GeneralLedgerCache.companyGeneralLedgersCacheKey(companyNanoId);

  const cacheKeys = useMemo(() => [cacheKey], [cacheKey]);

  const { value: generalLedger } =
    useReactQueryState<CompanyPaymentsGeneralLedgerType>(
      companyPaymentsMenuGeneralLedgerKey(companyNanoId)
    );

  return (
    <div className="p-4 pb-1 -mt-3 bg-gray-50 dark:bg-gray-950 border-b dark:border-gray-700">
      <CompanyPaymentsGeneralLedgersFilter
        className="flex flex-wrap -m-1"
        companyNanoId={companyNanoId}
      />

      <CheckPermissions
        action={
          TeamsPermissions.READ_PAYMENTS_INVOICES_OVERDUE_NOT_OVERDUE_AMOUNTS
        }
      >
        <CompanyPaymentsOverdueAmounts
          generalLedgerId={defaultGeneralLedgerId}
          companyContractorNanoId={companyContractorNanoId}
        />
      </CheckPermissions>

      <div className="mt-3 flex justify-between items-center">
        <div className="text-blue-600 dark:text-blue-400">
          <CheckPermissions
            action={
              TeamsPermissions.CREATE_COMPANY_PAYMENTS_MENU_GENERAL_LEDGERS
            }
          >
            <CreateGeneralLedgerModalButton
              cacheKeys={cacheKeys}
              className="p-0 space-x-1 inline-flex items-center whitespace-nowrap leading-5 focus:ring-base text-current hover:underline text-sm"
              companyNanoId={companyNanoId}
              icon={IconsEnum.PLUS_SOLID}
              iconClassName="mr-1 h-4 w-4"
              i18nText={generalLedgersKeys.createSubbook}
            />
          </CheckPermissions>
        </div>
        <div className="flex">
          <UpdateGeneralLedgerModalButton
            cacheKeys={cacheKeys}
            className="p-0 space-x-1 inline-flex items-center whitespace-nowrap leading-5 focus:ring-base text-current hover:underline text-sm"
            generalLedger={generalLedger}
            icon={IconsEnum.PENCIL_ALT_SOLID}
            iconClassName="mr-1 h-4 w-4"
            tooltipI18nText={generalLedgersKeys.editSubbook}
            tooltipPlacement={TooltipPlacement.TOP}
            disabled={!generalLedger}
          />
          {/* {IconsEnum.TRASH_SOLID} */}
        </div>
      </div>
    </div>
  );
}

export default CompanyPaymentsGeneralLedgersList;
