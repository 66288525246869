import { CompanyNanoID } from '../companies/companiesTypes';
import { ProjectNanoID } from '../projects/projectsTypes';
import { TaskNanoID } from '../tasks/tasksTypes';
import { UserNanoID } from '../users/usersTypes';
import { GeneralLedgerID } from '../generalLedgers/generalLedgersTypes';

export class InvoiceCache {
  static indexCacheKey() {
    return `invoices`;
  }

  static depositInvoicesCacheKey() {
    return 'deposit-invoices';
  }

  static contractorInvoicesCacheKey() {
    return 'contractor-invoices';
  }

  static contractorCurrentInvoicesCacheKey() {
    return 'contractor-current-invoices';
  }

  static contractorOpenInvoicesCacheKey() {
    return 'contractor-open-invoices';
  }

  static contractorSentInvoicesCacheKey() {
    return 'contractor-sent-invoices';
  }

  static contractorOverdueInvoicesCacheKey() {
    return 'contractor-overdue-invoices';
  }

  static itemsWithBitInvoicesCacheKey() {
    return 'items-with-bit-invoices';
  }

  static contractorInvoicesRangeCacheKey(gte: string, lte: string) {
    return `contractor-invoices-range-${gte}-${lte}`;
  }

  static contractorInvoicesOverdueAmountCacheKey(
    companyContractorNanoId: UserNanoID
  ) {
    return `contractor-invoices-overdue-amount-${companyContractorNanoId}`;
  }

  static generalLedgerInvoicesOverdueAmountCacheKey(
    generalLedgerId: GeneralLedgerID
  ) {
    return `general-ledger-invoices-overdue-amount-${generalLedgerId}`;
  }

  static companyInvoicesCacheKey(companyNanoId: CompanyNanoID) {
    return `company-invoices-${companyNanoId}`;
  }

  static companyContractorInvoicesCacheKey(companyNanoId: CompanyNanoID) {
    return `company-invoices-${companyNanoId}`;
  }

  static companyGroupedByContractorInvoicesCacheKey(
    companyNanoId: CompanyNanoID,
    groupedType: string
  ) {
    return `grouped-by-${groupedType}-company-invoices-${companyNanoId}`;
  }

  static companyInvoicesFilterCacheKey(
    companyNanoId: CompanyNanoID,
    filterSlug: string
  ) {
    return `company-invoices-${filterSlug}-${companyNanoId}`;
  }

  static contactorInvoicesCacheKey(contactorNanoId: UserNanoID) {
    return `contactor-invoices-${contactorNanoId}`;
  }

  static contactorInvoicesFilterCacheKey(
    contactorNanoId: UserNanoID,
    filterSlug: string
  ) {
    return `contactor-invoices-${filterSlug}-${contactorNanoId}`;
  }

  static paymentsInvoicesCacheKey() {
    return `payments-invoices`;
  }

  static showCacheKey() {
    return 'invoice';
  }

  static showRedirectCacheKey() {
    return 'invoice-redirect';
  }

  static showStatusCacheKey() {
    return 'invoice-status';
  }

  static showPayCacheKey() {
    return 'invoice-pay';
  }

  static taskParentInvoicesCacheKey(taskNanoId: TaskNanoID) {
    return `task-parent-invoices-${taskNanoId}`;
  }

  static projectInvoicesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-invoices-${projectNanoId}`;
  }

  static projectParentInvoicesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-parent-invoices-${projectNanoId}`;
  }

  static projectFinInvoicesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-fin-invoices`;
  }

  static projectDepositInvoicesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-deposit-invoices`;
  }

  static projectRegularInvoicesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-regular-invoices`;
  }

  static projectContractorInvoicesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-contractor-invoices`;
  }

  static taskInvoicesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-invoices`;
  }

  static dashboardDebtInvoicesCacheKey() {
    return 'dashboard-debt-invoices';
  }

  static groupedByCacheKey(key: string, groupedType: string) {
    return `grouped-by-${groupedType}-${key}`;
  }

  static projectSidebarDepositInvoicesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-sidebar-deposit-invoices`;
  }

  static projectSidebarRefundDepositInvoicesCacheKey(
    projectNanoId: ProjectNanoID
  ) {
    return `project-${projectNanoId}-sidebar-refund-deposit-invoices`;
  }

  static projectSidebarChargebackDepositInvoicesCacheKey(
    projectNanoId: ProjectNanoID
  ) {
    return `project-${projectNanoId}-sidebar-chargeback-deposit-invoices`;
  }

  static projectSidebarRegularInvoicesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-sidebar-regular-invoices`;
  }

  static projectSidebarRefundRegularInvoicesCacheKey(
    projectNanoId: ProjectNanoID
  ) {
    return `project-${projectNanoId}-sidebar-refund-regular-invoices`;
  }

  static projectSidebarChargebackRegularInvoicesCacheKey(
    projectNanoId: ProjectNanoID
  ) {
    return `project-${projectNanoId}-sidebar-chargeback-regular-invoices`;
  }

  static selectCacheKey() {
    return 'select-invoices';
  }
}
