import { gql } from 'graphql-request';

import { InvoiceID } from '../invoicesTypes';
import { MoneyType } from '../../../types';

export interface FetchInvoicesOverdueAmountQueryResponse {
  id: InvoiceID;
}

export interface FetchInvoicesOverdueAmountQueryMetaResponse {
  overdueAmount?: MoneyType;
  notOverdueAmount?: MoneyType;
}

export const FETCH_INVOICES_OVERDUE_AMOUNT_QUERY = gql`
  query InvoicesOverdueAmount(
    $filters: InvoicesFilters
    $sort: [InvoicesSortEnum!]
    $offset: Int
    $limit: Int
  ) {
    invoices(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      appVersion
      meta {
        overdueAmount
        notOverdueAmount
      }
    }
  }
`;
